<template>
  <div>
    <div class="m_Contact_page" v-if="isMobile">
      <div class="banner_wrap">
        <img class="banner" src="@/assets/contact_bg_m.png" />
        <p>欢迎业务洽谈 期待与您合作</p>
      </div>


      <div class="form_wrap">
        <div class="form_content">
          <div class="line">
            <div class="form_item">
              <p>公司名称</p>
              <input
                :class="{ error: error.company }"
                type="text"
                placeholder="请输入公司全称"
                v-model="form.company"
              />
            </div>
            <div class="form_item">
              <p>联系人</p>
              <input
                :class="{ error: error.name }"
                type="text"
                placeholder="请输入联系人名字全称"
                v-model="form.name"
              />
            </div>
          </div>

          <div class="line">
            <div class="form_item">
              <p>电子邮箱</p>
              <input
                :class="{ error: error.email }"
                type="text"
                placeholder="请输入正确的邮箱地址"
                v-model="form.email"
              />
            </div>
            <div class="form_item">
              <p>联系方式</p>
              <input
                :class="{ error: error.phone }"
                type="text"
                placeholder="请输入常用的手机号码"
                v-model="form.phone"
              />
            </div>
          </div>

          <div class="textarea_item">
            <p>需求描述</p>
            <textarea
              :class="{ error: error.content }"
              placeholder="请输入您的具体需求"
              v-model="form.content"
            ></textarea>
          </div>
        </div>
        <div class="btn_submit" @click="submit">确认提交</div>
      </div>
      
    </div>
    <div class="Contact_page" v-else>
      <div class="banner_wrap">
        <img class="banner" src="@/assets/contact_bg.png" />
        <div><p class="fadeInLeft slow animated wow">欢迎业务洽谈 期待与您合作</p></div>
      </div>


      <div class="form_wrap">
        <div class="form_content">
          <div class="line">
            <div class="form_item">
              <p>公司名称</p>
              <input
                :class="{ error: error.company }"
                type="text"
                placeholder="请输入公司全称"
                v-model="form.company"
              />
            </div>
            <div class="form_item">
              <p>联系人</p>
              <input
                :class="{ error: error.name }"
                type="text"
                placeholder="请输入联系人名字全称"
                v-model="form.name"
              />
            </div>
          </div>

          <div class="line">
            <div class="form_item">
              <p>电子邮箱</p>
              <input
                :class="{ error: error.email }"
                type="text"
                placeholder="请输入正确的邮箱地址"
                v-model="form.email"
              />
            </div>
            <div class="form_item">
              <p>联系方式</p>
              <input
                :class="{ error: error.phone }"
                type="text"
                placeholder="请输入常用的手机号码"
                v-model="form.phone"
              />
            </div>
          </div>

          <div class="textarea_item">
            <p>需求描述</p>
            <textarea
              :class="{ error: error.content }"
              placeholder="请输入您的具体需求"
              v-model="form.content"
            ></textarea>
          </div>
        </div>
        <div class="btn_submit" @click="submit">确认提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from "@/utils";
import Message from "vue-multiple-message";
import axios from "axios";
export default {
  name: "Contact",
  data() {
    return {
      isMobile: false,
      form: {
        name: "",
        email: "",
        company: "",
        phone: "",
        content: "",
      },
      error: {
        name: false,
        email: false,
        company: false,
        phone: false,
        content: false,
      },
    };
  },
  components: {},
  created() {
    this.isMobile = isMobile();
  },
  mounted() {
  },
  methods: {
    submit() {
      if (this.form.name === "") {
        this.error.name = true;
      } else {
        this.error.name = false;
      }
      if (this.form.email === "") {
        this.error.email = true;
      } else {
        this.error.email = false;
      }
      if (this.form.company === "") {
        this.error.company = true;
      } else {
        this.error.company = false;
      }
      if (this.form.phone === "") {
        this.error.phone = true;
      } else {
        this.error.phone = false;
      }
      if (this.form.content === "") {
        this.error.content = true;
      } else {
        this.error.content = false;
      }

      if (
        this.error.content ||
        this.error.phone ||
        this.error.company ||
        this.error.email ||
        this.error.name
      ) {
        Message.error("资料未正确填写");
        return;
      }

      axios({
        method: "post",
        url: "http://www.unre.com/Home/ContactUs",
        data: {
          name: this.form.name,
          email: this.form.email,
          company: this.form.company,
          phone: this.form.phone,
          content: this.form.content,
        },
      })
        .then(function (response) {
          console.log(response);
          Message("这是一条消息提示");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="less">
.m_Contact_page{
  .banner_wrap{
    position: relative;
    img{
      width: 100%;
    }
    p{
      width: 10rem;
font-size: 0.43rem;
color: #FCFCFC;
position: absolute;
top: 6.93rem;
left: 50%;
transform: translateX(-50%);
    }
  }

   .form_wrap {
    background-color: #fafafa;
    padding: 0.55rem 0 0.38rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .form_content {
      width: 9.15rem;
      margin-bottom: 0.64rem;
      .line {
        display: flex;
        justify-content: space-between;
      }
      .textarea_item {
        width: 100%;
        p {
font-size: 0.36rem;
          text-align: left;
          margin-bottom: 0.24rem;
          color: #808080;
        }
        textarea {
          outline: none;
          width: 100%;
          
height: 5.04rem;
          background: #ffffff;
          border: 0.01rem solid #dadada;
          padding: 0.24rem;
          font-size: 0.32rem;
          &.error {
            border-color: #f56c6c;
          }
        }
      }
      .form_item {
        color: #808080;
        width: 48%;
        margin-bottom: 0.26rem;
        p {
          font-size: 0.36rem;
          text-align: left;
          margin-bottom: 0.27rem;
        }
        input {
          outline: none;
          width: 100%;
height: 0.92rem;
          background: #ffffff;
          border: 0.01rem solid #dadada;
          padding: 0.24rem;
          font-size: 0.32rem;
          &.error {
            border-color: #f56c6c;
          }
        }
      }
    }
    .btn_submit {
      width: 2.45rem;
      
height: 0.84rem;
      background: #02aeec;
      
border-radius: 0.08rem 0.08rem 0.08rem 0.08rem;
      font-size: 0.32rem;
      color: #fcfcfc;
      line-height: 0.84rem;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }

}
.Contact_page {
  .banner_wrap {
    position: relative;
    img {
      display: block;
      width: 100%;
    }
    div{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    p {
      
      
      font-size: 40px;
      color: #fcfcfc;
    }
  }

  

  .form_wrap {
    background-color: #fafafa;
    padding: 0.27rem 0 0.38rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .form_content {
      width: 750px;
      margin-bottom: 0.25rem;
      .line {
        display: flex;
        justify-content: space-between;
      }
      .textarea_item {
        width: 100%;
        p {
          font-size: 16px;
          text-align: left;
          margin-bottom: 0.08rem;
          color: #808080;
        }
        textarea {
          font-family: Avenir;
          outline: none;
          width: 100%;
          height: 1.97rem;
          background: #ffffff;
          border: 0.01rem solid #dadada;
          padding: 10px;
          font-size: 16px;
          &.error {
            border-color: #f56c6c;
          }
        }
      }
      .form_item {
        color: #808080;
        width: 48%;
        margin-bottom: 0.13rem;
        p {
          font-size: 16px;
          text-align: left;
          margin-bottom: 0.08rem;
        }
        input {
          outline: none;
          width: 100%;
          height: 60px;
          background: #ffffff;
          border: 0.01rem solid #dadada;
          padding: 5px 8px;
          font-size: 16px;
          &.error {
            border-color: #f56c6c;
          }
        }
      }
    }
    .btn_submit {
      width: 180px;
      height: 60px;
      background: #02aeec;
      border-radius: 0.03rem 0.03rem 0.03rem 0.03rem;
      font-size: 16px;
      color: #fcfcfc;
      line-height: 60px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
</style>
